import React from 'react';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import Layout from '../layout';
import useWindowDimensions from '../../hooks/useWindowDimensions';

import Header from '../Header';
import BodyContainer from '../BodyContainer';
import BodyComponents from '../BodyComponents';

function generateBodySection({ component, params }, bodySectionId, mobileView) {
  if (BodyComponents[component]) {
    const SectionComponent = BodyComponents[component];
    // console.log(SectionComponent);
    return (
      <SectionComponent
        {...params}
        mobileView={mobileView}
        key={component + bodySectionId}
      />
    );
  } else {
    return <div>{component}</div>;
  }
}

const generateBodySections = (bodySections, mobileView) => {
  return Object.getOwnPropertyNames(bodySections).map((bodySectionId) => {
    // console.log(bodySections[bodySectionId]);
    return generateBodySection(
      bodySections[bodySectionId],
      bodySectionId,
      mobileView
    );
  });
};

const IndexPage = (props) => {
  const [t] = useTranslation(['index','translation']);
  const { width } = useWindowDimensions();

  let mobileView = false;

  const bodySections = {
    ...t('bodySections', { returnObjects: true }),
  };

  const headerProps = t('header', { returnObjects: true });
  const signupWidgetProps = t('signupWidget', { returnObjects: true });

  if (width < 750 && width > 380) {
    mobileView = 'mid';
  } else if (width <= 380) {
    mobileView = 'small';
  } else {
    mobileView = false;
  }

  return (
    <Layout>
      <Helmet>
        <title>{t('translation:siteTitle')}</title>
      </Helmet>
      <Header
        mobileView={mobileView}
        headerProps={headerProps}
        signupWidgetProps={signupWidgetProps}
      />
      <BodyContainer>
        {generateBodySections(bodySections, mobileView)}
      </BodyContainer>
    </Layout>
  );
};

export default IndexPage;
