import React, { useContext } from 'react';
import styled from 'styled-components';
import useWindowDimensions from '../hooks/useWindowDimensions';
import AssetsContext from './assetsContext';
import Img from 'gatsby-image/withIEPolyfill';
import JoinCampaignWidget from './HeaderComponents/JoinCampaignWidget';
import { generateSections } from './_generators';

const HeaderContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 1200px;
  margin: auto;
`;

const HeaderImageContainer = styled.div`
  width: 100%;
  height: ${(props) => props.dimensions.height}px;
  max-height: 610px;
  min-height: 369px;
  position: relative;
`;

const HeaderColorOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(154, 147, 147, 0.23);
  z-index: 99;
  height: 100%;
`;

// const HeaderImage = styled.img`
//   object-fit: cover;
//   width: 100%;
//   height: 100%;
//   object-position: ${(props) => (props.mobileView ? '' : 'right')};
// `;

const HeaderImageGatsby = styled(Img)`
  object-fit: cover;
  width: 100%;
  height: 100%;
  object-position: ${(props) => (props.mobileView ? '' : 'right')};
`;

// const LogoImageContainer = styled.div`
//   width: 100%;
//   height: 100%;
//   top: 0;
//   bottom: 0;
//   position: absolute;
//   width: 100%;
//   height: ${(props) => props.dimensions.height}px;
//   vertical-align: center;
// `;

// const LogoImage = styled.img`
//   display: block;
//   bottom: 40px;
//   margin: auto;
//   left: 0;
//   right: 0;
//   position: absolute;
//   width: ${(props) => props.width}px;
// `;

const HeaderChildrenOuterContainer = styled.div`
  width: 100%;
  max-width: ${p => p.theme.siteConfig.maxContentWidth ? p.theme.siteConfig.maxContentWidth : 990}px;
  position: ${p => p.mobileView ? 'relative' : 'absolute'};
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin-left: auto;
  margin-right: auto;
`

const HeaderChildrenContainer = styled.div`
  width: 350px;
  height: 100%;
  right: 10px;
  position: absolute;
  z-index: 100;
  top: 0;
  @media (max-width: ${p => p.theme.siteConfig.breakpoints.mobile}px) {
    width: 100%;
    height: auto;
    position: relative;
    margin-top: 0px;
    margin-bottom: 10px;
    right: unset;
  }
  @media (max-width: ${p => p.theme.siteConfig.breakpoints.smallMobile}px) {

  }

  > div {
    padding-top: 79px;
    padding-bottom: 10px;
    background-color: ${(props) => props.theme.headerBreakoutSection.background};
    height: 100%;
    box-sizing: border-box;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;

    @media (max-width: ${p => p.theme.siteConfig.breakpoints.mobile}px) {
      padding-top: 0px;
      padding-bottom: 0px;
      align-items: unset;
    }
    @media (max-width: ${p => p.theme.siteConfig.breakpoints.smallMobile}px) {

    }

  }

  

`;


const JoinWidget = styled(JoinCampaignWidget)``;

const Header = ({ className, headerProps, signupWidgetProps, mobileView }) => {
  const files = useContext(AssetsContext).fileUrls;
  // const imagesMaxUrls = useContext(AssetsContext).imagesMaxUrls;
  const imagesMaxByName = useContext(AssetsContext).imagesMaxByName;

  const { height, width } = useWindowDimensions();

  function getContainerDimensions(windowWidth) {
    let height;

    if (windowWidth > 952) {
      height = 610;
    } else if (windowWidth > 783) {
      height = 0.75 * windowWidth;
    } else {
      height = 0.9 * windowWidth;
    }

    return {
      height,
      width: windowWidth,
    };
  }

  return (
    <HeaderContainer className={className}>
      <HeaderImageContainer dimensions={getContainerDimensions(width)}>
        <HeaderImageGatsby
          fluid={imagesMaxByName[headerProps.headerImageName]}
          mobileView={mobileView}
          objectPosition={mobileView ? 'center center' : 'right center'}
          imgStyle={{ maxHeight: 610 }}
        />
        <HeaderColorOverlay
          dimensions={getContainerDimensions(height, width)}
        />
      </HeaderImageContainer>
      {signupWidgetProps ? (
        <JoinWidget
          anchorName={'getInvolved'}
          mobileView={mobileView}
          widgetProps={signupWidgetProps}
          logoUrl={files[signupWidgetProps.logoImageName]}
        />
      ) : (
        <HeaderChildrenOuterContainer mobileView={mobileView}>
          <HeaderChildrenContainer mobileView={mobileView}>
            <div id={"HeaderChildrenContainerContents"}>
              {/* <BigLogo backgroundColor={'none'} mobileView={mobileView} logoUrl={files[headerProps.logoImageName]} /> */}
              {generateSections(headerProps.childComponents, mobileView)}
            </div>
          </HeaderChildrenContainer>
        </HeaderChildrenOuterContainer>
      )}
    </HeaderContainer>
  );
};

export default Header;
